import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/Nav"
import Hero from "../components/Hero"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import SEO from "../components/seo/SEO"

const About = ({ data }) => {
  return (
    <>
      <SEO />
      <div className="gradient">
        <Nav active="about" />
        <Hero
          heading={"CONDITIONS GENERALES D’UTILISATION"}
          buttonText="Accéder"
          full={true}
          handleClick={() => {
            const element = document.getElementById("legal")
            if (element) element.scrollIntoView()
          }}
        />
      </div>

      <section className="bg-white py-8 container mx-auto m-8" id="legal">
        <div className="max-w-5xl">
          <p className="text-center mt-0 mb-12">
            Date de dernière mise à jour : 20-01-2020
          </p>
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Article 1 – MENTION LÉGALES
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
              Le présent site, accessible à l’URL <b><a href="https://class.oktopod.app">class.oktopod.app</a></b> (le « Site »), est édité par :
            <br /> <br />
            La société Oktopod, 74 Avenue Maréchal Foch, 43000 le
            Puy en Velay,
            <br /> <br />
            (Ci-après désigné l’« Exploitant »).
            <br /> <br />
            Le Site est hébergé par la société Netlify, Inc., situé 2325 3rd
            Street, Suite 215, San Francisco, California 94107.
            <br /> <br />
            Le Directeur de la publication du Site est Garnier Kristen.
            <br /> <br />
            L’Exploitant peut être joint à l’adresse mail suivante
            contact@oktopod.io
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Article 2 – DESCRIPTION DU SERVICE
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            Le Site est mis à la disposition de toute personne accédant au site
            (l’ « Utilisateur ») pour accéder au contenu éducatif.
            <br /> <br />
            Le Site n’est pas un site marchand et ne propose aucune transaction
            commerciale à distance.
            <br /> <br />
            L’Utilisateur demeure responsable des modalités et des conséquences
            de son accès au Site notamment par l’Internet. Cet accès peut
            impliquer le paiement de frais à des prestataires techniques tels
            que notamment des fournisseurs d’accès à l’Internet, lesquels
            demeurent à sa charge. En outre, l’Utilisateur devra fournir et être
            entièrement responsable des équipements nécessaires afin de se
            connecter au Site.
            <br /> <br />
            L’Utilisateur reconnait avoir vérifié que la configuration
            informatique qu’il utilise est sécurisée et en état de
            fonctionnement.
            <br /> <br />
            Les informations et services proposés par le Site sont gratuits, et
            accessibles 24h/24h et 7jours/7 jours, sauf en cas de force majeure,
            de pannes informatiques, d’opérations de maintenance ou de problèmes
            liés aux réseaux de télécommunications.
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Article 3 – PROPRIÉTÉ INTELLECTUELLE ET LICENCE D’UTILISATION DU
            SITE
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            L’Exploitant est seul titulaire de tous les éléments présents sur le
            Site, notamment et sans limitation, tous textes, fichiers, images
            animées ou non, photographies, vidéos, logos, dessins, modèles,
            logiciels, marques, identité visuelle, base de données, structure du
            Site et tous autres éléments de propriété intellectuelle et autres
            données ou informations (ci-après, les « Éléments ») qui sont
            protégés par les lois et règlements français et internationaux
            relatifs notamment à la propriété intellectuelle.
            <br /> <br />
            En conséquence, aucun des Éléments du Site ne pourra en tout ou
            partie être modifié, reproduit, copié, dupliqué, vendu, revendu,
            transmis, publié, communiqué, distribué, diffusé, représenté,
            stocké, utilisé, loué ou exploité de toute autre manière, à titre
            gratuit ou onéreux, par un Utilisateur ou par un tiers, quel que
            soient les moyens et/ou les supports utilisés, qu’ils soient connus
            ou inconnus à ce jour, sans l’autorisation préalable exprès et
            écrite de l’Exploitant au cas par cas, et l’Utilisateur est seul
            responsable de toute utilisation et/ou exploitation non autorisée.
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            ARTICLE 4 – RESPONSABILITÉ ET GARANTIE
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            L’Utilisateur reconnaît que les caractéristiques et les contraintes
            d'Internet ne permettent pas de garantir la sécurité, la
            disponibilité et l'intégrité des transmissions de données sur
            Internet. Ainsi, l’Exploitant ne garantit pas que le Site et ses
            services fonctionneront sans interruption ni erreur de
            fonctionnement. En particulier, leur exploitation pourra être
            momentanément interrompue pour cause de maintenance, de mises à jour
            ou d'améliorations techniques, ou pour en faire évoluer le contenu
            et/ou leur présentation.
            <br />
            <br />
            L’Exploitant ne peut être tenu pour responsable de l'utilisation qui
            serait faite du Site et de ses Éléments par les Utilisateurs en
            violation des présentes Conditions Générales d’Utilisation et des
            dommages directs ou indirects que cette utilisation pourrait causer
            à un Utilisateur ou à un tiers. En particulier, l’Exploitant ne peut
            être tenu pour responsable des fausses déclarations faites par un
            Utilisateur et de son comportement vis-à-vis des tiers. Dans le cas
            où la responsabilité de l’Exploitant serait recherchée à raison d'un
            tel comportement d’un de ses Utilisateurs, ce dernier s'engage à
            garantir l’Exploitant contre toute condamnation prononcée à son
            encontre ainsi qu’à rembourser l’Exploitant de l’ensemble des frais,
            notamment les honoraires d’avocats, engagés pour sa défense.
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            ARTICLE 5 – DONNÉES PERSONNELLES
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            Pour davantage d’informations concernant l’utilisation de données à
            caractère personnel par l’Exploitant, veuillez lire attentivement la
            Charte sur le respect de la vie privée (la « Charte »). Vous pouvez
            à tout moment consulter cette Charte sur le Site.
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Article 6 – LIENS HYPERTEXTES
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            Les liens hypertextes disponibles sur le Site peuvent renvoyer vers
            des sites tiers non édités par l’Exploitant. Ils sont fournis
            uniquement pour la convenance de l’Utilisateur, afin de faciliter
            l’utilisation des ressources disponibles sur l’Internet. Si
            l’Utilisateur utilise ces liens, il quittera le Site et acceptera
            alors d’utiliser les sites tiers à ses risques et périls ou le cas
            échéant conformément aux conditions qui les régissent.
            <br /> <br />
            L’Utilisateur reconnait que l’Exploitant ne contrôle ni ne contribue
            en aucune manière à l’élaboration des conditions d’utilisation et/ou
            du contenu s’appliquant à ou figurant sur ces sites tiers.
            <br /> <br />
            En conséquence, l’Exploitant ne saurait être tenu responsable de
            quelque façon que ce soit du fait de ces liens hypertextes.
            <br /> <br />
            En outre, l’Utilisateur reconnait que l’Exploitant ne saurait
            cautionner, garantir ou reprendre à son compte tout ou partie des
            conditions d’utilisation et/ou du contenu de ces sites tiers.
            <br /> <br />
            Le Site peut également contenir des liens hypertextes promotionnels
            et/ou bandeaux publicitaires renvoyant vers des sites tiers non
            édités par l’Exploitant.
            <br /> <br />
            L’Exploitant invite l’Utilisateur à lui signaler tout lien
            hypertexte présent sur le Site qui permettrait d’accéder à un site
            tiers proposant du contenu contraire aux lois et/ou aux bonnes
            mœurs.
            <br /> <br />
            L’Utilisateur ne pourra pas utiliser et/ou insérer de lien
            hypertexte pointant vers le site sans l’accord écrit et préalable de
            l’Exploitant au cas par cas.
          </p>

          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            ARTICLE 7 – DISPOSITION GÉNÉRALES
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>

          <p className="mt-12 mb-12">
            INTEGRALITÉ DE L’ACCORD DES PARTIES
            <br />
            <br />
            Les présentes Conditions Générales d’Utilisation constituent un
            contrat régissant les relations entre l’Utilisateur et l’Exploitant.
            Elles constituent l'intégralité des droits et obligations de
            l’Exploitant et de l’Utilisateur relatifs à leur objet. Si une ou
            plusieurs stipulations des présentes Conditions Générales
            d’Utilisation étaient déclarées nulles en application d'une loi,
            d'un règlement ou à la suite d'une décision définitive d'une
            juridiction compétente, les autres stipulations garderont toute leur
            force et leur portée. En outre, le fait pour une des parties aux
            présentes Conditions Générales d’Utilisation de ne pas se prévaloir
            d'un manquement de l'autre partie à l'une quelconque des
            dispositions des présentes Conditions Générales d’Utilisation ne
            saurait s'interpréter comme une renonciation de sa part à se
            prévaloir dans l'avenir d'un tel manquement.
            <br />
            <br />
            MODIFICATIONS DES CONDITIONS D'UTILISATION
            <br />
            <br />
            L’Exploitant se réserve le droit de modifier à tout moment et sans
            préavis le contenu du Site ou des services qui y sont disponibles,
            et/ou de cesser de manière temporaire ou définitive d’exploiter tout
            ou partie du Site.
            <br />
            <br />
            En outre, l’Exploitant se réserve le droit de modifier à tout moment
            et sans préavis la localisation du Site sur l’Internet, ainsi que
            les présentes Conditions Générales d’Utilisation. L’Utilisateur est
            donc tenu par conséquent de se reporter aux présentes Conditions
            Générales d’Utilisation avant toute utilisation du Site. En cas de
            modifications matérielles, l’Utilisateur sera informé au moyen d'un
            email et d’un avertissement sur le Site avant la mise en application
            de la modification.
            <br />
            <br />
            L’Utilisateur reconnait que l’Exploitant ne saurait être tenu
            responsable de quelque manière que ce soit envers lui ou tout tiers
            du fait de ces modifications, suspensions ou cessations.
            <br />
            <br />
            RÉCLAMATION - MÉDIATION
            <br />
            <br />
            En cas de litige, vous devez vous adresser en priorité au service
            client de l'entreprise aux coordonnées suivantes :
            support@oktopod.io.
            <br />
            <br />
            En cas d'échec de la demande de réclamation auprès du service client
            ou en l'absence de réponse de ce service dans un délai de 15 jours,
            le Client peut soumettre le différend relatif au bon de commande ou
            aux présentes CGV l'opposant à l’Exploitant au médiateur suivant :
            Kristen Garnier, kristen@oktopod.io.
            <br />
            <br />
            Le médiateur tentera, en toute indépendance et impartialité, de
            rapprocher les parties en vue d'aboutir à une solution amiable. Les
            parties restent libres d'accepter ou de refuser le recours à la
            médiation ainsi que, en cas de recours à la médiation, d'accepter ou
            de refuser la solution proposée par le médiateur.
            <br />
            <br />
            DROIT APPLICABLE
            <br />
            <br />
            Ces Conditions Générales d’Utilisation sont régies, interprétées et
            appliquées conformément au droit français.
            <br />
            <br />
            ACCEPTATION DES CONDITIONS GÉNÉRALES PAR L’UTILISATEUR
            <br />
            <br />
            Si l’Utilisateur décide de visite le Site, les présentes Conditions
            Générales d’Utilisation s’appliqueront automatiquement à son
            utilisation du Site. L’Utilisateur reconnait donc avoir pris
            connaissance des Conditions Générales d’Utilisation préalablement à
            son utilisation du Site, et déclare les accepter sans réserve.
            <br />
            <br />
            Les Conditions Générales d’Utilisation applicables à l’Utilisateur
            sont celles mises en ligne sur le Site. Le Site indiquera à
            l'Utilisateur que les présentes conditions générales ont été
            modifiées, l’Utilisateur devant se référer à la rubrique « CGU »
            pour vérifier les conditions générales en vigueur au moment de sa
            connexion.
          </p>
        </div>
      </section>

      <Contact />
      <Footer image={data.footerImage.childImageSharp.fluid} />
    </>
  )
}

export const query = graphql`
  query CGUQuery {
    site {
      siteMetadata {
        aboutDescription
        aboutMain
        aboutSubMain
      }
    }
    footerImage: file(relativePath: { eq: "logo.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default About
